import * as React from "react"
import { getCtas, getHtml, getImage } from "../utils"
import {
  Alignment,
  ContentfulSelfServicePageContent,
  ContentfulSelfServicePageSection,
} from "../types"
import { FeatureItemProps } from "../components/FeatureItem"
import FeatureItems, { FeatureItemsProps } from "../components/FeatureItems"

function ContentfulFeatureItems(section: ContentfulSelfServicePageSection) {
  const props = mapProps(section)

  return <FeatureItems {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): FeatureItemsProps {
  // Pull the Content, Items, and Appearance blocks out of the Section block that is passed in
  const { content, items: itemList, alignment = Alignment.Center } = entry
  // Normalize the data
  const featureItemsContent =
    content || ({} as ContentfulSelfServicePageContent)
  const items =
    itemList?.map(
      (item: ContentfulSelfServicePageContent): FeatureItemProps => {
        const [primaryCta] = getCtas(item)
        return {
          icon:
            Array.isArray(item.images) && item.images.length
              ? undefined
              : getImage(item.icon),
          image:
            Array.isArray(item.images) && item.images.length
              ? item.images[0].gatsbyImage
              : undefined,
          title: item.primaryText,
          emTitle: item.secondaryText,
          text: getHtml(item.description),
          link: primaryCta?.to,
          textAlign: alignment,
          embedId: item.videoEmbedId,
        }
      }
    ) ?? []

  // Return the props that will be passed to FeatureGrid
  return {
    heading: featureItemsContent?.primaryText,
    alignment,
    items,
  }
}
export default ContentfulFeatureItems
