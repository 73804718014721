import * as React from "react"
import { ContentfulSelfServicePageSection } from "../types"
import Hero, { HeroProps } from "../components/Hero"
import { getHtml, getImage, getCtas } from "../utils"

const ContentfulHero = (section: ContentfulSelfServicePageSection) => {
  const props = mapProps(section)
  return <Hero {...props} />
}

function mapProps(entry: ContentfulSelfServicePageSection): HeroProps {
  // Pull the Content and Appearance blocks out of the Section block that is passed in
  const { content, appearance } = entry

  // Normalize the data
  const heroContent = content
  const backgroundImages = appearance?.backgroundImages || []
  const [primaryCta, secondaryCta] = getCtas(heroContent)
  const iconSrc = getImage(heroContent?.icon)
  const leftBackgroundSrc = getImage(backgroundImages[0])
  const rightBackgroundSrc = getImage(backgroundImages[1])

  // Return the props that will be passed to Hero
  return {
    iconSrc,
    rightBackgroundSrc,
    leftBackgroundSrc,
    title: heroContent?.primaryText,
    eyebrow: heroContent?.secondaryText,
    lede: getHtml(heroContent?.description),
    primaryCta,
    secondaryCta,
  }
}

export default ContentfulHero
