import { ColorSchemeProvider } from "components/self-service-landing-page/color-scheme-provider"
import Banner from "components/self-service-landing-page/containers/ContentfulBanner"
import ContactForm from "components/self-service-landing-page/containers/ContentfulContactForm"
import FeatureColumn from "components/self-service-landing-page/containers/ContentfulFeatureColumn"
import FeaturedSites from "components/self-service-landing-page/containers/ContentfulFeaturedSites"
import FeatureGrid from "components/self-service-landing-page/containers/ContentfulFeatureGrid"
import FeatureItems from "components/self-service-landing-page/containers/ContentfulFeatureItems"
import Hero from "components/self-service-landing-page/containers/ContentfulHero"
import LogoList from "components/self-service-landing-page/containers/ContentfulLogoList"
import ResourceGrid from "components/self-service-landing-page/containers/ContentfulResourceGrid"
import RichTextBlock from "components/self-service-landing-page/containers/ContentfulRichTextBlock"
import Subnavigation from "components/self-service-landing-page/containers/ContentfulSubnavigation"
import Testimonial from "components/self-service-landing-page/containers/ContentfulTestimonial"
import TwoColumnHero from "components/self-service-landing-page/containers/ContentfulTwoColumnHero"
import WalkThrough from "components/self-service-landing-page/containers/ContentfulWalkThrough"
import { mainContainerCss } from "components/self-service-landing-page/style-utils"
import { graphql } from "gatsby"
import React from "react"
import Layout from "../../layout"
import SiteHead from "../../head"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"

const componentsMap = new Map([
  ["Banner", Banner],
  ["ContactForm", ContactForm],
  ["Hero", Hero],
  ["FeatureColumn", FeatureColumn],
  ["FeatureItems", FeatureItems],
  ["FeatureGrid", FeatureGrid],
  ["FeaturedSites", FeaturedSites],
  ["LogoList", LogoList],
  ["ResourceGrid", ResourceGrid],
  ["RichTextBlock", RichTextBlock],
  ["Subnavigation", Subnavigation],
  ["Testimonial", Testimonial],
  ["TwoColumnHero", TwoColumnHero],
  ["Walkthrough", WalkThrough],
])

const prepareComponents = data => {
  const safeData = data?.contentfulSelfServiceLandingPage?.sections || []
  return safeData.map((section, index) => {
    const Component = componentsMap.get(section.layout)
    const theme = section?.appearance?.theme || "DEFAULT"

    if (Component) {
      return (
        <React.Fragment key={`${section.layout}-${index}`}>
          <ColorSchemeProvider colorScheme={theme}>
            {/* This should be refactored to a section prop once all components have been updated */}
            <Component {...section} />
          </ColorSchemeProvider>
        </React.Fragment>
      )
    }

    return null
  })
}

export function Head(props) {
  const page = props.data.contentfulSelfServiceLandingPage || {}
  const title = page.metaTitle || page.name
  const noindex = page.seoIndex === false

  return (
    <SiteHead
      {...props}
      title={title}
      description={page.metaDescription}
      image={page.socialMediaImage?.file?.url}
      noindex={noindex}
    />
  )
}

export default function SelfServiceLandingPage({ data, location }) {
  const Components = prepareComponents(data)
  const { pathname } = location
  const isHome = pathname === "/"

  return (
    <Layout
      pathname={pathname}
      inverted={isHome}
      background={isHome ? "#11081f" : false}
    >
      <SkipNavTarget />
      <main css={mainContainerCss}>{Components}</main>
    </Layout>
  )
}

export const sslpResourceList = graphql`
  fragment SSLPResourceList on ContentfulSelfServicePageResourceList {
    heading
    description {
      childMarkdownRemark {
        html
      }
    }
    contentType
  }
`

export const sslpContent = graphql`
  fragment SSLPContent on ContentfulSelfServicePageContent {
    name
    primaryText
    secondaryText
    videoEmbedId
    images {
      file {
        url
      }
      fixed: gatsbyImage(layout: FIXED, width: 200)
      constrained: gatsbyImage(layout: CONSTRAINED, width: 800)
      fluid: gatsbyImage(layout: FULL_WIDTH, width: 800)
    }
    description {
      childMarkdownRemark {
        html
      }
    }
    icon {
      file {
        url
      }
    }
    logos {
      name
      image {
        file {
          url
        }
      }
    }
    ctas {
      href
      anchorText
    }
    form {
      id
      marketoFormCampaignObject {
        formId
        name
      }
      thankYouMessage {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

export const pageQuery = graphql`
  query SelfServiceLandingPage($id: String!) {
    contentfulSelfServiceLandingPage(id: { eq: $id }) {
      id
      name
      slug
      seoIndex
      sections {
        internal {
          type
        }
        layout
        alignment
        content {
          ...SSLPContent
          ...SSLPResourceList
        }
        items {
          contentful_id
          name
          primaryText
          secondaryText
          videoEmbedId
          images {
            gatsbyImage(layout: CONSTRAINED, width: 800)
          }
          ctas {
            href
            anchorText
          }
          description {
            childMarkdownRemark {
              html
            }
          }
          icon {
            file {
              url
            }
          }
        }
        appearance {
          theme
          backgroundImages {
            file {
              url
            }
            gatsbyImage(layout: FULL_WIDTH)
          }
          verticalMargin
          name
        }
      }
      metaTitle
      metaDescription
      socialMediaImage {
        file {
          url
        }
      }
    }
  }
`
